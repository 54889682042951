<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { ActivationStatus, Access, AccessInfo } from "@/types";
import ActivationSuccessIcon from "@/components/elements/activate/ActivationSuccessIcon.vue";
import { useRouter } from "vue-router";
import { useMainStore } from "@/stores/main";
import api from "@/api";
import useTracking from "@/composables/useTracking";

export default defineComponent({
    name: "ActivationSuccess",
    components: { ActivationSuccessIcon },
    props: {
        showDiscard: {
            type: Boolean,
            required: false,
            default: false,
        },
        themeBlue: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup() {
        const router = useRouter();
        const store = useMainStore();
        const { clickEvent } = useTracking();

        const status = ref<ActivationStatus>("idle");
        const receivedAccess = ref<Access | null>(null);

        const idleElement = ref<HTMLElement | null>(null);
        const loadingElement = ref<HTMLElement | null>(null);
        const height = ref(0);

        const activate = () => {
            status.value = "loading";
            clickEvent("activate-video", null);
            api.activateVideo()
                .then((accessInfo: AccessInfo) => {
                    status.value = "success";
                    receivedAccess.value = accessInfo.access;
                })
                .catch(() => {
                    status.value = "error";
                });
        };

        const discardActivatePopup = () => {
            clickEvent("postpone-activate-video", null);
            store.discardedActivatePopup = true;
        };

        const applySuccess = () => {
            // this reloads the page, updates the content for new access
            router.go(0);
            // this closes the popup
            if (receivedAccess.value) {
                store.access = receivedAccess.value;
            }
        };

        onMounted(() => {
            if (idleElement.value) {
                const minHeight = 156;
                height.value = Math.max(
                    idleElement.value.offsetHeight,
                    minHeight
                );
            }
        });

        return {
            status,
            idleElement,
            loadingElement,
            height,
            applySuccess,
            activate,
            discardActivatePopup,
            store,
        };
    },
});
</script>

<template>
    <div class="ActivationSuccess">
        <div
            v-if="status === 'idle'"
            ref="idleElement"
            :style="{ 'min-height': height + 'px' }"
        >
            <p>{{ $t("modules.activate.popup.body") }}</p>

            <etd-container gap="l">
                <etd-suggestion
                    v-if="!store.access.has_own_exam"
                    id="activate-suggestion"
                    :background-color="
                        themeBlue
                            ? 'var(--color-blue-4)'
                            : 'var(--color-suggestion)'
                    "
                    :color="
                        themeBlue ? 'var(--color-white)' : 'var(--color-black)'
                    "
                >
                    <component :is="themeBlue ? 'strong' : 'div'">
                        {{ $t("modules.activate.popup.info") }}
                    </component>
                </etd-suggestion>

                <etd-button
                    class="activate-button"
                    @click="activate"
                    size="big"
                    style="max-width: 360px"
                >
                    {{ $t("modules.activate.popup.activate") }}
                </etd-button>

                <etd-button
                    v-if="showDiscard"
                    id="activate-discard-button"
                    variant="secondary"
                    size="big"
                    @click="discardActivatePopup"
                >
                    {{ $t("modules.activate.popup.later") }}
                </etd-button>
            </etd-container>
        </div>

        <div
            ref="loadingElement"
            class="ActivationSuccess__loading"
            :style="{ 'min-height': height + 'px' }"
            v-if="status === 'loading' || status === 'success'"
        >
            <activation-success-icon :success="status === 'success'" />

            <div class="ActivationSuccess__confirm">
                <etd-button
                    v-if="status === 'success'"
                    @click="applySuccess"
                    id="apply-activate"
                >
                    {{ $t("modules.activate.popup.applySuccess") }}
                </etd-button>
            </div>
        </div>

        <div v-if="status === 'error'">error</div>
    </div>
</template>

<style lang="scss" scoped>
.ActivationSuccess {
    &__loading {
        padding-top: var(--spacing-xxl);
    }

    &__confirm {
        display: flex;
        justify-content: center;
        margin-top: var(--spacing-l);
    }
}
</style>
