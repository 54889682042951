import { defineStore } from "pinia";
import {
    User,
    CourseTree,
    ProgressCollection,
    Access,
    CourseProperties,
} from "@/types";

interface MainState {
    version: string;
    menu: boolean;
    discardedActivatePopup: boolean;
    hasLoggedIn: boolean;
    //
    user: User | null;
    access: Access;
    course: CourseProperties;
    progressIsUpToDate: boolean;
    courseTree: CourseTree | null;
    progress: ProgressCollection;
    bannerActive: boolean;
}

export const useMainStore = defineStore("main", {
    state: () => {
        return {
            version: "",
            menu: false,
            discardedActivatePopup: false,
            hasLoggedIn: false,
            user: null,
            progressIsUpToDate: false,
            courseTree: null,
            progress: [],
            access: {
                practice: "unavailable",
                video: "unavailable",
                has_own_exam: false,
                use_extended_exams: false,
            },
            course: {
                has_video_course: true,
                has_exams: true,
            },
            bannerActive: true,
        } as MainState;
    },
    getters: {
        userName(state: MainState) {
            if (state.user) {
                return state.user.name;
            } else {
                return "";
            }
        },
        versionStringToNumber(state) {
            if (state.version === "" || state.version.includes("localhost")) {
                return 0;
            } else {
                const withoutV = state.version.split("v")[1];
                const base = withoutV.split("-")[0];
                const numbers = base.split(".");
                const major = Number(numbers[0]);
                const minor = Number(numbers[1]);
                const fix = Number(numbers[2]);
                return major * 10000 + minor * 100 + fix;
            }
        },
    },
    actions: {},
});
