<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "Banner",
    props: {
        content: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const close = () => emit("close-banner");

        return {
            close,
        };
    },
});
</script>

<template>
    <etd-popup close-button @close="close">
        <template #title>&nbsp;</template>
        <template #body>
            <div class="Banner" v-html="content" />
        </template>
    </etd-popup>
</template>
