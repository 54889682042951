import { Dictionary } from "@/types";

const dict: Dictionary = {
    account: "Profiel",
    back: "Terug",
    back_to_course: "Terug naar de cursus",
    back_to_overview: "Terug naar overzicht",
    back_to_exams: "Terug naar examenoverzicht",
    cancel: "Annuleren",
    change_password: "Wachtwoord wijzigen",
    close: "Sluiten",
    completed: "Voltooid",
    continue: "Doorgaan",
    correct: "Goed",
    course: "Cursus",
    dashboard: "Dashboard",
    ebook: "E-book",
    email: "E-mail",
    email_placeholder: "voorbeeld{'@'}domein.nl",
    email_invalid_message: "Dit is geen geldig e-mailadres",
    exam_already_finished:
        "Je hebt dit examen al afgerond. Je kunt hem onbeperkt opnieuw doen of je kunt de uitslag bekijken.",
    exams: "Examens",
    examSegment: "Examenonderdeel",
    exercise: "Oefenen",
    exit: "Stoppen",
    failed: "Gezakt",
    faq: "Veelgestelde vragen",
    field_not_empty_message: "Dit veld mag niet leeg zijn",
    forgot_password: "Wachtwoord vergeten",
    helpdesk: "Helpdesk",
    home: "Home",
    incorrect: "Fout",
    login: "Inloggen",
    logout: "Uitloggen",
    name: "Naam",
    next: "Volgende",
    next_chapter: "Volgend hoofdstuk",
    next_segment: "Volgend onderdeel",
    impossible_going_back: "Terugkeren is daarna niet meer mogelijk!",
    of: "Van",
    order: "Bestellen",
    passed: "Geslaagd",
    password: "Wachtwoord",
    password_current: "Huidig wachtwoord",
    password_new: "Nieuw wachtwoord",
    password_confirm: "Bevestig je wachtwoord",
    password_confirm_description: "Beide wachtwoorden dienen gelijk te zijn",
    password_strength_hint_pw8cap1numb1:
        "Je wachtwoord moet minstens 8 karakters, een hoofdletter en 1 cijfer bevatten",
    practise: "Oefenen",
    previous: "Vorige",
    proceed: "Verder gaan",
    question: "Vraag",
    redo_exam: "Examen opnieuw doen",
    registration_number: "Aanmeldnummer",
    reset: "Reset",
    show_answer: "Toon antwoord",
    something_went_wrong: "Oops... er ging iets mis",
    start_exam: "Examen starten",
    start_segment: "Start onderdeel",
    stop: "Stoppen",
    submit_exam: "Examen inleveren",
    subscribe: "Inschrijven",
    temporary_starting_from: "Tijdelijk vanaf",
    to_results: "Bekijk uitslag",
    total: "Totaal",
    try_again: "Opnieuw proberen",
    try_exam_again: "Probeer opnieuw",
    use_only_numbers: "Vul alleen een getal in",
    valid_until: "Geldig tot",
    videos: "Video's",
    wrong_combination_email_password:
        "Verkeerde combinatie van e-mail en wachtwoord",
    yes: "Ja",
    //
    segment: {
        result: {
            head: "Overzicht",
            body: "Gebruik dit overzicht om snel naar een vraag te gaan.",
            hint: "Je kunt nog terug om je antwoorden aan te passen. Druk op een vraag om terug te keren naar het examen.",
            types: {
                unanswered: "Onbeantwoord",
                answered: "Beantwoord",
                flagged: "Gemarkeerd",
            },
        },
    },
    modules: {
        questions: {
            types: {
                multiple_response: {
                    instruction: "Meerdere antwoorden kunnen goed zijn",
                },
            },
        },
        exam: {
            title: "Examens",
            body: "<p>Klik hier om direct naar de CBR-oefenexamens te gaan.</p>",
        },
        activate: {
            banner: "Activeer je pakket om te beginnen",
            popup: {
                title: "Activeer je pakket",
                body: 'Druk op "Activeer nu" voor volledige toegang tot de cursus.',
                info: "Je account wordt anders automatisch geactiveerd voorafgaand aan je examen",
                activate: "Activeer nu",
                later: "Ik doe dit later",
                applySuccess: "Okay, beginnen maar!",
            },
        },
        autoPlayAudio: {
            button: "Voorlezen",
            title: "Voorlezen",
            body: "De vragen worden automatisch voorgelezen.",
            confirm: "Voorlezen aanzetten",
        },
    },
    //
    pages: {
        upgrade: {
            head: "Geen toegang tot de cursus",
            body: "<p>Wil je volledige toegang tot onze online videocursus? Kies dan hieronder je pakket.</p>",
            packages: "Kies het pakket dat het beste bij jou past",
            combination: {
                package: {
                    head: "Kies je pakket",
                    body: "Selecteer hoe lang je toegang wilt tot de videocursus.",
                },
                exam: {
                    head: "Kies je examens",
                    body: "Wil je nóg beter voorbereid je examen in gaan?",
                },
            },
        },
        demoResult: {
            passed: {
                sub: "Gefeliciteerd!",
                main: "Je hebt het examen gehaald!",
            },
            failed: {
                sub: "Helaas...",
                main: "Je bent nog niet helemaal klaar voor het examen.",
            },
            cta: {
                head: "Verder oefenen?",
            },
        },
        homepage: {
            head: "Welkom {name}",
            body: "Welkom op je persoonlijke dashboard van 123-theorie! Je kunt hieronder doorgaan waar je gebleven bent en zien wat je al gedaan hebt.",
            bodyNotStarted:
                "Welkom op je persoonlijke dashboard van 123-theorie! Klik hieronder om naar de cursus te gaan en te beginnen met oefenen.",
            toCurrentSegment: "Doorgaan met oefenen",
            startCourse: "Beginnen met oefenen",
            timeLeft: {
                head: "{package} pakket",
                body: "Hiernaast zie je hoeveel tijd je nog over hebt.",
                score: "Tijd over:<br> {timeLeft}",
            },
            examsDone: "{done} van {total} examens behaald",
            chaptersDone: "{done} van {total} hoofdstukken afgerond",
            streak: "{streak} day streak",
            points: "{points} punten",
        },
        login: {
            head: "Inloggen",
            body: "Typ hieronder je gebruikersnaam en wachtwoord om in te loggen. Wachtwoord vergeten? Klik dan onderaan op 'wachtwoord vergeten'.",
            resetSuccess:
                "Je wachtwoord is gereset. Log nu in met je nieuwe wachtwoord.",
            accessExpired: {
                head: "Toegang verlopen",
                body: "Je toegang tot deze applicatie is verlopen. Neem contact op met de helpdesk voor meer informatie.",
            },
        },
        forgotPassword: {
            head: "Wachtwoord vergeten",
            body: "Vul hieronder je e-mailadres in om je wachtwoord opnieuw in te stellen.",
            submit: "Wachtwoord resetten",
            response: "Verzoek verstuurd",
            instruction:
                "Bekijk je e-mail voor de instructies om je wachtwoord opnieuw in te stellen.<br><br>(Let op: kijk ook in spam/reclame)",
            back: "Terug naar het loginscherm",
        },
        resetPassword: {
            head: "Wachtwoord resetten",
            submit: "Wachtwoord resetten",
            passwordError:
                "Zorg dat je wachtwoord hetzelfde is bij het herhalen.",
        },
        helpdesk: {
            head: "Helpdesk",
            body: "Heb je alle veelgestelde vragen bekeken en kom je er nog niet uit? Neem dan contact op met onze helpdesk, we staan tijdens kantooruren klaar om je te helpen!",
            sidebar: {
                head: "Contact opnemen",
                body: "Heb je alle veelgestelde vragen bekeken en kom je er nog niet uit? Neem dan contact op met onze helpdesk, we staan tijdens kantooruren klaar om je te helpen!",
            },
        },
        info: {
            head: "Over deze applicatie",
        },
        account: {
            head: "Profiel van {name}",
            head_basic: "Mijn profiel",
            body: "<p>Hieronder kun je alle informatie over je pakket en je account vinden. Ook kun je hier jouw wachtwoord veranderen. Voor vragen kun je contact opnemen met de klantenservice.</p>",
            info: {
                head: "Pakketgegevens",
            },
            user: {
                head: "Mijn gegevens",
            },
            extendedExam: {
                head: "Examens oefenen",
                label: "Ik oefen examens met extra tijd en met mogelijkheid tot vragen voorlezen.",
                description:
                    "Hier kun je aangeven of je het examen met extra tijd wil oefenen.",
            },
        },
        course: {
            body: "<p>Welkom in het online platform van 123-theorie! Je kunt vanaf deze pagina naar alle hoofdstukken. In de hoofdstukken kun je ons E-book lezen en vragen oefenen.</p>",
            noAccess: {
                // dit is als practice unavailable is, dit is meestal als je examendatum geweest is
                // vandaar taalgebruik in verleden tijd
                title: "Geen toegang tot de cursus",
                body: "<p>Je hebt geen toegang meer tot de cursusinhoud. Heb jij een dagcursus gepland staan, maar geen toegang meer tot deze omgeving of wil je volledige toegang tot onze online videocursus? Neem dan contact op met de klantenservice.</p>",
                button: "Videocursus bestellen",
            },
        },
        segment: {
            practice: {
                head: "Oefen dit onderdeel",
            },
            suggestion: {
                head: "Let op",
                body: "Weet je het zeker? Je hebt nog niet alle oefeningen gedaan. Het is niet aan te raden om hoofdstukken over te slaan.",
                cancel: "In dit onderdeel blijven",
                confirm: "Ja, ga naar het volgende hoofdstuk.",
            },
            noAccess: {
                // offline user
                unavailable: {
                    title: "Beperkt toegang",
                    body: "Je hebt geen toegang tot het videomateriaal, omdat jij een klassikale cursus hebt.",
                    order: "Koop volledig pakket",
                },
                expired: {
                    title: "Beperkt toegang",
                    body: "Je hebt geen toegang meer tot het videomateriaal, omdat je pakket verlopen is. Je kunt hieronder jouw pakket verlengen.",
                    order: "Koop opnieuw toegang",
                },
                inactive: {
                    title: "Activeer je account ",
                    body: "Je hebt nog geen toegang tot het videomateriaal. Activeer eerst je pakket.",
                    button: "Activeer",
                },
            },
        },
        exercise: {
            back_modal: {
                head: "Stoppen met oefenen",
                body: "Je verlaat nu deze oefening. Weet je het zeker?",
                exit: "Ja, terug naar '{segment}'",
                continue: "Verder met oefenen",
            },
            feedback: "Uitleg",
        },
        exerciseResult: {
            head: "Resultaten",
            body: "Je hebt {correctAnswers} van de {questions} vragen goed beantwoord.",
        },
        exams: {
            head: "Examens",
            body: "<p>Hieronder zie je alle examens in jouw pakket. Je kunt een examen starten door erop te klikken.</p>",
            buy: {
                head: "Meer examens nodig?",
                body: "<p>Wil je nóg beter voorbereid je examen in gaan? Hier kun je extra oefenexamens bijkopen.</p>",
                maxReached: "Je hebt al het maximale aantal examens gekocht.",
            },
            result: {
                passed: {
                    head: "Gefeliciteerd!",
                    body: "<p>Je bent geslaagd voor je theorie-examen!</p>",
                },
                failed: {
                    head: "Helaas..",
                    body: "<p>Je bent gezakt voor je theorie-examen.</p><p>Probeer het nog een keer.</p>",
                },
                toFeedback: "Toon foute antwoorden",
                examsegment:
                    "Je hebt {fails} van de {questions} vragen fout. De fouten zijn hieronder gemarkeerd.",
                chapter: {
                    head: "Oefenen",
                    body: "<p>Klik hieronder op een hoofdstuk om nog wat extra te oefenen.</p>",
                    fails: "{fails} fouten",
                    fail: "1 fout",
                },
                item: {
                    correct: "Goed",
                    incorrect: "Fout",
                    blank: "Niet ingevuld",
                    yourAnswer: "Jouw antwoord",
                    correctAnswer: "Goede antwoord",
                },
            },
            feedback: {
                head: "Examen inzien",
                subTitle: "Jouw fouten inzien",
                bodyFailed:
                    "Je bent gezakt voor je theorie-examen. Hieronder kun je je fouten inzien.",
                bodyPassed:
                    "Je bent geslaagd voor je theorie-examen. Hieronder kun je je fouten inzien.",
            },
            popup: {
                head: "Examen verlaten?",
                body: "Je verliest mogelijk gegevens.",
                confirm: "Examen verlaten",
            },
            noAccess: {
                practiceNotActive: {
                    body: "Je toegang tot de oefenomgeving is verlopen. Dit kan verschillende oorzaken hebben. Neem contact op met de klantenservice om je verder te helpen.",
                    button: "Helpdesk",
                },
                expired: {
                    body: "Verleng je toegang tot de oefenomgeving om examens kunnen bijkopen",
                    button: "Bestel videocursus",
                },
            },
        },
        myProducts: {
            head: "Pakketgegevens",
        },
        order: {
            head: "Examens kopen",
            body: "<p>Hieronder kun je kiezen welk pakketje examens je wilt bijkopen.</p>",
            placeholder: "Kies het aantal examens",
            overview: {
                head: "Jouw bestelling",
            },
        },
        updatePassword: {
            head: "Wachtwoord aanpassen",
            body: "<p>Pas hieronder je wachtwoord aan.</p>",
            submit: "Wachtwoord wijzigen",
            confirmation_message: "Je wachtwoord is aangepast.",
            wrong_password_current:
                "Je huidige wachtwoord klopt niet. Je wachtwoord is daarom niet aangepast.",
        },
    },
};

export default dict;
