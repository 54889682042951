<script lang="ts">
import { defineComponent } from "vue";
import ActivationSuccess from "@/components/elements/activate/ActivationSuccess.vue";

export default defineComponent({
    name: "ActivatePopup",
    components: { ActivationSuccess },
    props: {},
});
</script>

<template>
    <etd-popup id="activate-popup">
        <template #title>
            <div class="ActivatePopup__title">
                <etd-icon icon-id="face-sleeping" icon-style="duotone" />
                {{ $t("modules.activate.popup.title") }}
            </div>
        </template>

        <template #body>
            <activation-success show-discard />
        </template>
    </etd-popup>
</template>

<style lang="scss" scoped>
.ActivatePopup {
    &__title {
        display: flex;
        align-items: center;
        gap: var(--spacing-m);

        .Icon {
            color: var(--color-blue-5);
        }
    }
}
</style>
