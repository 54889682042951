import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-success"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "success-checkmark",
    "data-success": _ctx.success
  }, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "success-checkmark__circle" }, null, -1)),
    (_ctx.success)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "icon-line line-tip" }, null, -1)),
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "icon-line line-long" }, null, -1))
        ], 64))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}