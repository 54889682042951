<script lang="ts">
import { defineComponent, ref } from "vue";
import api from "@/api";
import { useRouter } from "vue-router";
import i18n from "@/i18n";
import { isBackendError } from "@/tools/handle-error";
import { ReturnedError } from "@/types";
import { FormConfig } from "@/types/etd";
import { useMainStore } from "@/stores/main";
import useTracking from "@/composables/useTracking";

interface AuthenticationResponse {
    access_token: string;
}

export default defineComponent({
    name: "LoginPanel",
    setup(props, { emit }) {
        const store = useMainStore();
        const router = useRouter();
        const { t } = i18n.global;
        const { clickEvent } = useTracking();

        const wrongCombinationEmailPassword = ref(false);

        const setToken = (token: string) => {
            localStorage.token = token;
        };

        const gotoForgotPassword = () => {
            router.push({ name: "ForgotPassword" });
        };

        const subscribeHref = window.config.planmoduleUrl;

        const formValue = ref({
            email: "",
            password: "",
        });

        const formConfig = ref<FormConfig>({
            settings: {
                displayRequiredMark: false,
                displayPositiveFeedback: false,
                validateAt: "submit",
            },
            cards: [
                {
                    type: "transparent",
                    sections: [
                        {
                            type: "regular",
                            rows: [
                                {
                                    items: [
                                        {
                                            key: "email",
                                            label: t("email"),
                                            placeholder: t("email_placeholder"),
                                            required: true,
                                            type: "email",
                                            editable: true,
                                            validation: [
                                                {
                                                    hint: t(
                                                        "email_invalid_message"
                                                    ),
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    items: [
                                        {
                                            key: "password",
                                            label: t("password"),
                                            required: true,
                                            type: "password",
                                            editable: true,
                                            validation: [
                                                {
                                                    hint: t(
                                                        "field_not_empty_message"
                                                    ),
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        });

        const atSubmit = (payload: { email: string; password: string }) => {
            wrongCombinationEmailPassword.value = false;
            api.getToken(payload.email, payload.password)
                .then((response: AuthenticationResponse) => {
                    clickEvent("login", { success: true });
                    store.hasLoggedIn = true;
                    wrongCombinationEmailPassword.value = false;
                    setToken(response.access_token);
                    setTimeout(() => {
                        emit("success");
                    });
                })
                .catch((error: ReturnedError) => {
                    clickEvent("login", { success: false });
                    if (
                        isBackendError(error) &&
                        error.response.status === 400
                    ) {
                        wrongCombinationEmailPassword.value = true;
                        setToken("");
                        emit("fail");
                    } else {
                        // todo
                    }
                });
        };

        return {
            formValue,
            formConfig,
            wrongCombinationEmailPassword,
            gotoForgotPassword,
            subscribeHref,
            atSubmit,
        };
    },
});
</script>

<template>
    <etd-container gap="xl">
        <div>
            <div v-html="$t('pages.login.body')" />
        </div>

        <etd-form
            v-model:formValue="formValue"
            :form-config="formConfig"
            @submit="atSubmit"
        >
            <template #submit="{ atSubmit }">
                <etd-button
                    id="login-submit"
                    theme="orange"
                    size="big"
                    @click="atSubmit"
                >
                    {{ $t("login") }}
                </etd-button>
            </template>
        </etd-form>

        <etd-form-error-message
            v-if="wrongCombinationEmailPassword"
            id="authentication-feedback"
        >
            {{ $t("wrong_combination_email_password") }}
        </etd-form-error-message>

        <etd-navigation-button
            id="forgot-password"
            :label="$t('forgot_password')"
            direction="right"
            @click="gotoForgotPassword"
        />

        <etd-navigation-button
            id="subscribe-button"
            :label="$t('subscribe')"
            direction="right"
            :href="subscribeHref"
            target="_self"
        />
    </etd-container>
</template>

<style lang="scss" scoped></style>
