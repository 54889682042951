import { RoutePath } from "@/types";

export const accountRoutes: RoutePath[] = [
    {
        path: "/",
        name: "Home",
    },
    {
        path: "/account",
        name: "Account",
    },
    {
        path: "/wachtwoord-aanpassen",
        name: "UpdatePassword",
    },
];
