<script setup lang="ts">
import LoginPanel from "@/components/elements/login/LoginPanel.vue";
import IdentityScreen from "@/components/elements/identity-screen/IdentityScreen.vue";
import { defineProps, defineEmits } from "vue";

defineProps({
    reset: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const emit = defineEmits(["success", "fail"]);

const atSuccess = () => emit("success");
const atFail = () => emit("fail");
</script>

<template>
    <identity-screen id="login-page">
        <etd-suggestion
            id="reset-success-feedback"
            v-if="reset"
            style="margin-bottom: var(--spacing-xxxxxl)"
        >
            {{ $t("pages.login.resetSuccess") }}
        </etd-suggestion>

        <etd-logo :height="40" style="margin-bottom: var(--spacing-xxxxl)" />

        <h4>
            {{ $t("pages.login.head") }}
        </h4>

        <login-panel @success="atSuccess" @fail="atFail" />
    </identity-screen>
</template>
