<script lang="ts">
import { defineComponent } from "vue";
import { useMainStore } from "@/stores/main";

export default defineComponent({
    name: "ActivateBanner",
    components: {},
    props: {},
    setup() {
        const store = useMainStore();

        const activate = () => (store.discardedActivatePopup = false);
        return {
            activate,
        };
    },
});
</script>

<template>
    <button class="ActivateBanner" @click="activate">
        <etd-pagewrap :width="$pagewrap">
            <div class="ActivateBanner__content">
                <div class="ActivateBanner__text">
                    <etd-icon icon-id="face-sleeping" icon-style="duotone" />
                    {{ $t("modules.activate.banner") }}
                </div>
                <etd-icon icon-id="arrow-right" icon-style="duotone" />
            </div>
        </etd-pagewrap>
    </button>
</template>

<style lang="scss" scoped>
.ActivateBanner {
    height: var(--banner-height);
    width: 100%;
    z-index: 100;
    background: var(--color-blue-5);
    color: var(--color-white);
    padding: var(--spacing-m);

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__text {
        display: flex;
        align-items: center;
        gap: var(--spacing-m);
        font-weight: 700;
    }

    .Icon {
        font-size: 24px;
    }
}
</style>
