import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_icon_button = _resolveComponent("etd-icon-button")!

  return (_openBlock(), _createBlock(_component_etd_icon_button, {
    onClick: _ctx.gotoHelpdesk,
    "icon-id": "headset",
    color: "blue-5",
    label: _ctx.mq.t === 's' ? '' : _ctx.$t('helpdesk')
  }, null, 8, ["onClick", "label"]))
}