import { defineStore } from "pinia";
import {
    PageTitle,
    TimedEvent,
    TrackEventTimed,
    TrackItem,
    TrackOptions,
} from "@/types/tracking";

interface TrackingState {
    timedEvents: TimedEvent[];
    applicationSleeps: boolean;
    sleepTimer: number;
    unsentPayload: TrackOptions | null;
}

export const useTrackingStore = defineStore("tracking", {
    state: () => {
        return {
            timedEvents: [],
            applicationSleeps: false,
            sleepTimer: 0,
            unsentPayload: null,
        } as TrackingState;
    },
    getters: {},
    actions: {
        startTimedEvent(
            event: TrackEventTimed,
            id: number | string,
            pageTitle: PageTitle
        ) {
            const trackItem: TrackItem = {
                event: event,
                page: pageTitle,
                data: {},
            };
            const timedEvent = {
                trackItem,
                id: id,
                start: new Date().getTime(),
            };
            this.timedEvents.push(timedEvent);
        },
        getTimedEvent(event: TrackEventTimed, id: number | string) {
            // start from the end, there might be open timed events
            for (let i = this.timedEvents.length - 1; i >= 0; i--) {
                const timedEvent = this.timedEvents[i];
                if (
                    timedEvent.trackItem.event === event &&
                    timedEvent.id === id
                ) {
                    return timedEvent;
                }
            }
            return null;
        },
        removeTimedEvent(timedEvent: TimedEvent) {
            const index = this.timedEvents.indexOf(timedEvent);
            this.timedEvents.splice(index, 1);
        },
    },
});
