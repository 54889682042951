import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ActivateBanner__content" }
const _hoisted_2 = { class: "ActivateBanner__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_icon = _resolveComponent("etd-icon")!
  const _component_etd_pagewrap = _resolveComponent("etd-pagewrap")!

  return (_openBlock(), _createElementBlock("button", {
    class: "ActivateBanner",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.activate && _ctx.activate(...args)))
  }, [
    _createVNode(_component_etd_pagewrap, { width: _ctx.$pagewrap }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_etd_icon, {
              "icon-id": "face-sleeping",
              "icon-style": "duotone"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("modules.activate.banner")), 1)
          ]),
          _createVNode(_component_etd_icon, {
            "icon-id": "arrow-right",
            "icon-style": "duotone"
          })
        ])
      ]),
      _: 1
    }, 8, ["width"])
  ]))
}