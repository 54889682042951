import { RoutePath } from "@/types";

export const courseRoutes: RoutePath[] = [
    {
        path: "/cursus",
        name: "Course",
    },
    {
        path: "/cursus/:chapterTitle/:segmentTitle/:segmentId",
        name: "CourseSegment",
    },
    {
        path: "/cursus/:chapterTitle/:segmentTitle/:segmentId/e-book",
        name: "Ebook",
    },
    {
        path: "/cursus/:chapterTitle/:segmentTitle/:segmentId/oefenen/:question",
        name: "Exercise",
    },
    {
        path: "/cursus/:chapterTitle/:segmentTitle/:segmentId/oefenen/resultaat",
        name: "ExerciseResult",
    },
];
