import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ActivatePopup__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_icon = _resolveComponent("etd-icon")!
  const _component_activation_success = _resolveComponent("activation-success")!
  const _component_etd_popup = _resolveComponent("etd-popup")!

  return (_openBlock(), _createBlock(_component_etd_popup, { id: "activate-popup" }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_etd_icon, {
          "icon-id": "face-sleeping",
          "icon-style": "duotone"
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.$t("modules.activate.popup.title")), 1)
      ])
    ]),
    body: _withCtx(() => [
      _createVNode(_component_activation_success, { "show-discard": "" })
    ]),
    _: 1
  }))
}