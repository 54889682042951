import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_button = _resolveComponent("etd-button")!
  const _component_etd_form = _resolveComponent("etd-form")!
  const _component_etd_form_error_message = _resolveComponent("etd-form-error-message")!
  const _component_etd_navigation_button = _resolveComponent("etd-navigation-button")!
  const _component_etd_container = _resolveComponent("etd-container")!

  return (_openBlock(), _createBlock(_component_etd_container, { gap: "xl" }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", {
          innerHTML: _ctx.$t('pages.login.body')
        }, null, 8, _hoisted_1)
      ]),
      _createVNode(_component_etd_form, {
        formValue: _ctx.formValue,
        "onUpdate:formValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formValue) = $event)),
        "form-config": _ctx.formConfig,
        onSubmit: _ctx.atSubmit
      }, {
        submit: _withCtx(({ atSubmit }) => [
          _createVNode(_component_etd_button, {
            id: "login-submit",
            theme: "orange",
            size: "big",
            onClick: atSubmit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("login")), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 1
      }, 8, ["formValue", "form-config", "onSubmit"]),
      (_ctx.wrongCombinationEmailPassword)
        ? (_openBlock(), _createBlock(_component_etd_form_error_message, {
            key: 0,
            id: "authentication-feedback"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("wrong_combination_email_password")), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_etd_navigation_button, {
        id: "forgot-password",
        label: _ctx.$t('forgot_password'),
        direction: "right",
        onClick: _ctx.gotoForgotPassword
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_etd_navigation_button, {
        id: "subscribe-button",
        label: _ctx.$t('subscribe'),
        direction: "right",
        href: _ctx.subscribeHref,
        target: "_self"
      }, null, 8, ["label", "href"])
    ]),
    _: 1
  }))
}