import useTracking from "@/composables/useTracking";
import { useTrackingStore } from "@/stores/tracking";

export default function useApplicationUse() {
    const store = useTrackingStore();
    const sleepTime = 3 * 60 * 1000;
    const { startTimingEvent, stopTimingEvent } = useTracking();

    const initApplicationUse = () => {
        document.addEventListener("visibilitychange", function () {
            if (document.visibilityState === "hidden") {
                stopTimingEvent("use-application", 1, {});
                clearSleepTimer();
            }

            if (document.visibilityState === "visible") {
                startTimingEvent("use-application", 1);
                startSleepTimer();
            }
        });

        startTimingEvent("use-application", 1);

        startSleepTimer();
        // on each click we reset the sleep timer
        window.addEventListener("click", startSleepTimer);
    };

    const continueUse = () => {
        store.applicationSleeps = false;
        startTimingEvent("use-application", 1);
        startSleepTimer();
    };

    const clearSleepTimer = () => {
        if (store.sleepTimer) {
            clearTimeout(store.sleepTimer);
        }
    };

    const startSleepTimer = () => {
        // clearSleepTimer();
        //
        // store.sleepTimer = setTimeout(() => {
        //     stopTimingEvent("use-application", 1, {});
        //     store.applicationSleeps = true;
        // }, sleepTime);
    };

    return {
        initApplicationUse,
        continueUse,
    };
}
