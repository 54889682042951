import { ref } from "vue";
import { ReturnedResponse, ReturnedError } from "@/types";
import constants from "@/data/constants";
import { useMainStore } from "@/stores/main";

type GetData = () => Promise<ReturnedResponse>;

export default function useFetch(getData: GetData, requestNeedsToken: boolean) {
    const store = useMainStore();
    const showLoader = ref(false);
    const data = ref(<null | ReturnedResponse>null);
    const error = ref(<null | ReturnedError>null);
    const errorMessage = ref("");
    const isRequesting = ref(false);
    const isSuccessful = ref(false);

    const time = 0;
    let timer: number | null = null;

    const request = () => {
        isRequesting.value = true;
        timer = setTimeout(() => {
            showLoader.value = true;
        }, time);
        getData()
            .then((response: ReturnedResponse) => {
                if (requestNeedsToken) {
                    store.hasLoggedIn = true;
                }
                data.value = response;
                error.value = null;
                errorMessage.value = "";
                isSuccessful.value = true;
            })
            .catch((err: ReturnedError) => {
                error.value = err;
                isSuccessful.value = false;
                if (err instanceof Error) {
                    errorMessage.value = err.message;
                } else {
                    errorMessage.value = "General error";
                }
            })
            .finally(() => {
                if (timer) {
                    clearTimeout(timer);
                }
                isRequesting.value = false;
                showLoader.value = false;
            });
    };

    if (!requestNeedsToken || localStorage.token) {
        request();
    } else {
        error.value = new Error(constants.NO_TOKEN_MESSAGE);
    }

    return {
        showLoader,
        error,
        errorMessage,
        data,
        request,
        isRequesting,
        isSuccessful,
    };
}
