import { RoutePath } from "@/types";

export const examRoutes: RoutePath[] = [
    {
        path: "/examens",
        name: "Exams",
    },
    {
        path: "/examen/:id/",
        name: "Exam",
    },
    {
        path: "/examen/:id/onderdeel/:segment",
        name: "Segment",
    },
    {
        path: "/examen/:id/onderdeel/:segment/resultaat",
        name: "SegmentResult",
    },
    {
        path: "/examen/:id/onderdeel/:segment/vraag/:question",
        name: "QuestionPage",
    },
    {
        path: "/examen/:id/uitslag/:session",
        name: "ExamResult",
    },
    {
        path: "/examen/:id/feedback/:session",
        name: "ExamFeedback",
    },
];
