import { examRoutes } from "./exam";
import { accountRoutes } from "./account";
import { courseRoutes } from "./course";
import { otherRoutes } from "./other";
import { pagesWithoutLoginRoutes } from "./pages-without-login";

const pagesWith = [
    ...courseRoutes,
    ...examRoutes,
    ...accountRoutes,
    ...otherRoutes,
].map((r) => {
    return {
        meta: {
            requiresAuthentication: true,
        },
        ...r,
    };
});

const pagesWithout = pagesWithoutLoginRoutes.map((r) => {
    return {
        meta: {
            requiresAuthentication: false,
        },
        ...r,
    };
});

const paths = [...pagesWith, ...pagesWithout];

export default paths;
