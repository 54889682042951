<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "ActivationSuccessIcon",
    props: {
        success: {
            type: Boolean,
            required: true,
        },
    },
});
</script>

<template>
    <div class="success-checkmark" :data-success="success">
        <div class="success-checkmark__circle"></div>

        <template v-if="success">
            <span class="icon-line line-tip"></span>
            <span class="icon-line line-long"></span>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.success-checkmark {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    position: relative;

    &__circle {
        width: 100%;
        height: 100%;
        border: 5px solid #97dfaa;
        border-bottom-color: var(--color-feedback-success);
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation-idle 1s linear infinite;
        transition: border-color 0.5s ease-in;
    }

    .icon-line {
        height: 5px;
        background-color: #4caf50;
        display: block;
        border-radius: 2px;
        position: absolute;
        z-index: 10;

        &.line-tip {
            top: 46px;
            left: 14px;
            width: 25px;
            transform: rotate(45deg);
            animation: icon-line-tip 0.75s;
        }

        &.line-long {
            top: 38px;
            right: 8px;
            width: 47px;
            transform: rotate(-45deg);
            animation: icon-line-long 0.75s;
        }
    }

    &[data-success="false"] {
        //
    }

    &[data-success="true"] {
        .success-checkmark__circle {
            border: 5px solid var(--color-feedback-success);
        }
    }
}
@keyframes rotation-idle {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}
</style>
