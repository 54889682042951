<script lang="ts">
import { defineComponent, inject } from "vue";
import { useRouter } from "vue-router";
import { MQ } from "@/types";

export default defineComponent({
    name: "HelpdeskButton",
    setup() {
        const router = useRouter();
        const mq: MQ = inject("mq");

        return {
            gotoHelpdesk: () => router.push({ name: "Helpdesk" }),
            mq,
        };
    },
});
</script>

<template>
    <etd-icon-button
        @click="gotoHelpdesk"
        icon-id="headset"
        color="blue-5"
        :label="mq.t === 's' ? '' : $t('helpdesk')"
    />
</template>
