import { RoutePath } from "@/types";

export const pagesWithoutLoginRoutes: RoutePath[] = [
    {
        path: "/help",
        name: "Helpdesk",
    },
    {
        path: "/login",
        name: "Login",
    },
    {
        path: "/wachtwoord-vergeten",
        name: "ForgotPassword",
    },
    {
        path: "/wachtwoord-resetten",
        name: "ResetPassword",
    },
    {
        path: "/time",
        name: "Time",
    },
    {
        path: "/info",
        name: "Info",
    },
    {
        path: "/demo",
        name: "Demo",
    },
    {
        path: "/demo-uitslag",
        name: "DemoResult",
    },
];
