import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

import LoginPanel from "@/components/elements/login/LoginPanel.vue";
import IdentityScreen from "@/components/elements/identity-screen/IdentityScreen.vue";



export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPage',
  props: {
    reset: {
        type: Boolean,
        required: false,
        default: false,
    },
},
  emits: ["success", "fail"],
  setup(__props, { emit: __emit }) {



const emit = __emit;

const atSuccess = () => emit("success");
const atFail = () => emit("fail");

return (_ctx: any,_cache: any) => {
  const _component_etd_suggestion = _resolveComponent("etd-suggestion")!
  const _component_etd_logo = _resolveComponent("etd-logo")!

  return (_openBlock(), _createBlock(IdentityScreen, { id: "login-page" }, {
    default: _withCtx(() => [
      (__props.reset)
        ? (_openBlock(), _createBlock(_component_etd_suggestion, {
            key: 0,
            id: "reset-success-feedback",
            style: {"margin-bottom":"var(--spacing-xxxxxl)"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("pages.login.resetSuccess")), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_etd_logo, {
        height: 40,
        style: {"margin-bottom":"var(--spacing-xxxxl)"}
      }),
      _createElementVNode("h4", null, _toDisplayString(_ctx.$t("pages.login.head")), 1),
      _createVNode(LoginPanel, {
        onSuccess: atSuccess,
        onFail: atFail
      })
    ]),
    _: 1
  }))
}
}

})