export interface FaqItemHardcoded {
    question: string;
    answer: string;
    hideForHasNotVideoCourse: boolean;
}

export interface FaqSectionHardcoded {
    id: number;
    name: string;
    slug: string;
    items: FaqItemHardcoded[];
}

const questionFeedback: FaqItemHardcoded = {
    question: "Wat moet ik doen als er iets in het platform niet werkt?",
    answer: "Je kunt feedback geven aan de linkerkant van de pagina. Ook kun je ons altijd een whatsapp sturen met een screenshot van de plek waar het misgaat. Wij gaan dan aan de slag om jouw issue op te lossen.",
    hideForHasNotVideoCourse: false,
};

const questionNoAnswer: FaqItemHardcoded = {
    question: "Waarom kan ik bij het examen niet zien welke vraag ik fout heb?",
    answer: "Het examen werkt exact hetzelfde als bij het CBR. Dat betekent dat je bij het examen geen uitleg krijgt over je fouten. Bij alle oefenvragen krijg je direct feedback met duidelijke uitleg. De oefenvragen zijn dus om te leren, de examens zijn om te testen of je alles goed begrepen hebt en om te kijken of je klaar bent voor het CBR-examen.",
    hideForHasNotVideoCourse: false,
};

export const faqVideoActivated: FaqSectionHardcoded[] = [
    {
        id: 1,
        name: "Videocursus",
        slug: "videocursus",
        items: [
            {
                question: "Kan ik meer examens oefenen?",
                answer: "Er zit een standaard aantal examens bij jouw pakket. Je kunt extra examens bijkopen, klik <a href='/examens'>hier om naar het examenoverzicht te gaan</a>.",
                hideForHasNotVideoCourse: true,
            },
            {
                question: "Hoe lang heb ik toegang?",
                answer: "Op jouw <a href='/'>Dashboard</a> kun je precies zien hoe lang jij nog toegang hebt.",
                hideForHasNotVideoCourse: false,
            },
            questionFeedback,
            questionNoAnswer,
        ],
    },
];

// Niet-geactiveerd (videocursus klant)
export const faqVideoNotActivated: FaqSectionHardcoded[] = [
    {
        id: 1,
        name: "Videocursus",
        slug: "videocursus",
        items: [
            {
                question: "Waarom zit er een slotje op de video's?",
                answer: "Je hebt je videopakket nog niet geactiveerd. Zodra je geactiveerd hebt, krijg je direct toegang tot de video's. Klik <a href='/account'>om je pakket te activeren</a>.",
                hideForHasNotVideoCourse: true,
            },
            questionFeedback,
            questionNoAnswer,
        ],
    },
];

export const faqOffline: FaqSectionHardcoded[] = [
    {
        id: 1,
        name: "Videocursus",
        slug: "video-cursus",
        items: [
            {
                question: "Waarom zit er een slotje op de video's?",
                answer: "Jij hebt een dagcursus pakket gekocht. Wil je ook een videocursus erbij? Neem dan contact op met de klantenservice.",
                hideForHasNotVideoCourse: true,
            },
            {
                question: "Kan ik meer examens oefenen?",
                answer: "Er zit een standaard aantal examens bij jouw pakket. Je kunt extra examens bijkopen, klik <a href='/examens'> hier om naar het examenoverzicht te gaan</a>.",
                hideForHasNotVideoCourse: true,
            },
            questionFeedback,
            questionNoAnswer,
            {
                question: "Tot wanneer kan ik oefenen?",
                answer: "Je kunt inloggen tot en met je cursusdag, ook als je nu een andere datum ziet staan.",
                hideForHasNotVideoCourse: false,
            },
        ],
    },
    {
        id: 2,
        name: "Overige",
        slug: "other",
        items: [
            {
                question: "Waarom moet ik jullie machtigen?",
                answer:
                    "<p>Wij kunnen pas een examen voor jou reserveren, als jij ons gemachtigd hebt om dat te doen. Dit is in 2 minuten geregeld. Klik op de link hieronder voor een duidelijk stappenplan:</p>" +
                    "\n" +
                    "<a href='https://www.123-theorie.nl/machtigen/' target='_blank'>www.123-theorie.nl/machtigen</a>",
                hideForHasNotVideoCourse: false,
            },
            {
                question: "Ben ik al ingepland voor de cursus?",
                answer: "Dit kun je terugvinden in de mail. Direct wanneer jouw cursusdatum bekend is, krijg je van ons een e-mail met alle informatie (datum, adres en tijdstip). Let op: deze mail kan in je spam/reclame belanden. Hou de mail dus goed in de gaten! Ook sturen wij, een week voor de cursus, voor de zekerheid nog een SMS met dezelfde info naar het bij ons bekende 06-nummer.",
                hideForHasNotVideoCourse: false,
            },
            {
                question:
                    "Mijn cursusdatum komt me niet uit, kan ik verplaatsen?",
                answer: "Tot 10 dagen van tevoren kunnen wij jouw afspraak eenmalig kosteloos verplaatsen. Neem hiervoor contact op met de klantenservice.",
                hideForHasNotVideoCourse: false,
            },
            {
                question: "Hoe kom ik bij het CBR?",
                answer: "Onze cursuslocatie is vaak op loopafstand van het CBR. Indien dit niet het geval is, zorgen wij voor vervoer naar het CBR met de taxi. Na het examen kun je vanaf het CBR zelf naar huis.",
                hideForHasNotVideoCourse: false,
            },
        ],
    },
];
