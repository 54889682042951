import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { computed, onMounted, ref, watch } from "vue";
import { AccessInfo } from "@/types";
import { useRoute, useRouter } from "vue-router";
import useFetch from "@/composables/useFetch";
import api from "@/api";
import { useMainStore } from "@/stores/main";
import LoginPage from "@/components/elements/login/LoginPage.vue";

interface AccessFetch {
    data: AccessInfo | null;
    request: () => void;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AccessLayer',
  setup(__props) {

const store = useMainStore();
const route = useRoute();
const router = useRouter();

const fetch = ref<AccessFetch | null>(null);

const requiresAuthentication = computed(
    () => route.meta.requiresAuthentication
);

const accessData = computed(() => fetch.value?.data);

let authenticationRequested = false;
const requestAuthentication = () => {
    if (!authenticationRequested) {
        authenticationRequested = true;
        fetch.value = useFetch(api.getAccessInfo, true);
    }
};

const hasAccess = computed(() => {
    return !route.meta.requiresAuthentication || fetch.value?.isSuccessful;
});

const showLoader = computed(() => fetch.value?.showLoader);

const atSuccess = () => (fetch.value = useFetch(api.getAccessInfo, true));

watch(
    () => accessData.value,
    () => {
        if (accessData.value) {
            store.access = accessData.value.access;
            store.course = accessData.value.course;
            store.user = accessData.value.user;
        }
    }
);

// if we change from a page without login to a login-required page
watch(
    () => requiresAuthentication.value,
    () => {
        if (requiresAuthentication.value && !accessData.value) {
            requestAuthentication();
        }
    }
);

onMounted(async () => {
    await router.isReady();
    if (route.meta.requiresAuthentication) {
        requestAuthentication();
    }
});

return (_ctx: any,_cache: any) => {
  const _component_etd_loading = _resolveComponent("etd-loading")!
  const _component_etd_container = _resolveComponent("etd-container")!

  return (showLoader.value)
    ? (_openBlock(), _createBlock(_component_etd_container, {
        key: 0,
        align: "center",
        justify: "center",
        style: {"height":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_etd_loading)
        ]),
        _: 1
      }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (hasAccess.value)
          ? _renderSlot(_ctx.$slots, "default", { key: 0 })
          : (_openBlock(), _createBlock(LoginPage, {
              key: 1,
              onSuccess: atSuccess
            }))
      ], 64))
}
}

})