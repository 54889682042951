const QUESTION_TYPE_ID: Record<number, string> = {
    1: "DragMultiple",
    2: "DragSingle",
    3: "Input",
    4: "Spot",
    5: "MultipleChoice",
    6: "MultipleResponse",
};

export default {
    DRAG_N_DROP_SIZE: 48,
    DRAG_N_DROP_BUFFER: 40,
    FORCED_VALUE: -1,
    IMAGE_RATIO: 1920 / 1080,
    MOST_SELECTED_ONLINE_PACKAGE_INDEX: 2,
    NO_TOKEN_MESSAGE: "No token",
    PLACEHOLDER_IMAGE: "/assets/img/image-placeholder.png",
    PRODUCT_CATEGORY_ICON: {
        ATH: "motorcycle",
        AMTH: "moped",
        BTH: "car",
    },
    QUESTION_TYPE_ID,
};
