import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-mq", "data-has-banner"]
const _hoisted_2 = { class: "App__view" }

import { computed, provide, onMounted } from "vue";
import { useMainStore } from "@/stores/main";
import useMediaQuery from "@innouveau/123-etd/dist/composables/useMediaQuery.min.mjs";
import ActivateBanner from "@/components/elements/activate/ActivateBanner.vue";
import ActivatePopup from "@/components/elements/activate/ActivatePopup.vue";
import Banner from "@/components/elements/banner/Banner.vue";
import useApplicationUse from "@/composables/useApplicationUse";
import AccessLayer from "@/components/elements/access-layer/AccessLayer.vue";
import HelpdeskButton from "@/components/elements/buttons/HelpdeskButton.vue";
import { useTrackingStore } from "@/stores/tracking";
import useTracking from "@/composables/useTracking";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const store = useMainStore();
const trackingStore = useTrackingStore();
const { initApplicationUse } = useApplicationUse();
const { videoSeenEvent } = useTracking();

const { mq } = useMediaQuery();
provide("mq", mq);

const showActivateBanner = computed(() => {
    return store.hasLoggedIn && store.access.video === "inactive";
});

const showActivatePopup = computed(() => {
    return (
        store.hasLoggedIn &&
        store.access.video === "inactive" &&
        !store.discardedActivatePopup
    );
});

const hasBanner = computed(() => window.config.banner.active);
const bannerContent = computed(() =>
    hasBanner.value ? window.config.banner.content : ""
);
const closeBanner = () => {
    store.bannerActive = false;
};

const setVersion = async () => {
    const response = await fetch("/version.json");
    const json = await response.json();
    store.version = json.version;
};

const initApplicationLeave = () => {
    const beforeUnloadListener = () => {
        if (trackingStore.unsentPayload) {
            videoSeenEvent(trackingStore.unsentPayload);
            trackingStore.unsentPayload = null;
            return "Weet je zeker dat je de pagina wilt verlaten?";
        } else {
            return undefined;
        }
    };

    window.onbeforeunload = beforeUnloadListener;
};

onMounted(async () => {
    await setVersion();
    initApplicationUse();
    initApplicationLeave();
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_etd_error_boundary = _resolveComponent("etd-error-boundary")!

  return (_openBlock(), _createElementBlock("div", {
    class: "App",
    "data-mq": _unref(mq).t,
    "data-has-banner": showActivateBanner.value
  }, [
    (showActivateBanner.value)
      ? (_openBlock(), _createBlock(ActivateBanner, { key: 0 }))
      : _createCommentVNode("", true),
    (showActivatePopup.value)
      ? (_openBlock(), _createBlock(ActivatePopup, { key: 1 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(AccessLayer, null, {
        default: _withCtx(() => [
          _createVNode(_component_etd_error_boundary, null, {
            content: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            footer: _withCtx(({ atClose }) => [
              _createVNode(HelpdeskButton, { onClick: atClose }, null, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    (hasBanner.value && _unref(store).bannerActive)
      ? (_openBlock(), _createBlock(Banner, {
          key: 2,
          onCloseBanner: closeBanner,
          content: bannerContent.value
        }, null, 8, ["content"]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}
}

})