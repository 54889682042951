<template>
    <div class="IdentityScreen">
        <div class="IdentityScreen__section">
            <div class="IdentityScreen__panel">
                <slot />
            </div>
        </div>

        <div class="IdentityScreen__section">
            <img
                src="/assets/img/123-theorie-login-artwork.webp"
                alt="Afbeelding van laptop en mobiel waarop de video-applicatie gebruikt wordt"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.IdentityScreen {
    display: flex;
    height: 100%;

    &__section {
        width: 50%;
        height: 100%;
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: var(--spacing-xxxl);

        &:nth-child(2) {
            background: var(--color-blue-3);

            img {
                width: 100%;
                height: auto;
                max-width: 598px;
            }
        }
    }

    &__panel {
        width: 460px;
        max-width: 100%;
    }
}

[data-mq="s"] {
    .IdentityScreen__section {
        width: 100%;

        &:nth-child(2) {
            display: none;
        }
    }
}
</style>
