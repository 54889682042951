import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import etdPlugin from "@innouveau/123-etd";
import i18n from "./i18n";
import { createPinia } from "pinia";

const app = createApp(App);

app.use(createPinia()).use(router).use(i18n).use(etdPlugin).mount("#app");

app.config.globalProperties.$pagewrap = 1200;
app.config.globalProperties.$stackedScreens = ["s"];
